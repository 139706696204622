import React, { FC, useEffect, useState, useRef } from "react";
import { Modal, Button, Carousel, Steps, message, Form } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setCurrentSection,
  getWorkshops,
  getRecentlyAddedIndex,
} from "../../stores";

import { updateWorkshop, clearRecentlyAdded } from "shared";

import { Workshop } from "shared/src/data-models/Workshop";

import { WorkshopSetup } from "../AccountSetup/WorkshopSetup";
import { QuizModal } from "../../app/Components/Modals/QuizModal";
import WorkshopPackages, {
  IPackage,
} from "../../app/Components/Workshop/WorkshopPackages";
import WorkshopServices from "../../app/Components/WorkshopServices";

import { PackagesAPI, ServicesAPI } from "../../app/Services/axios";

import { CKCloseIcon } from "../../assets/SvgIcons";
import { CkMessage } from "../../CkUI";
import "./styles.css";

interface IProps {}

interface WorkshopPackage {
  price: number;
  servicePackageSetId: number;
  servicePackageSet: IPackage;
  workshopAppointmentPackageMaps: any[];
  workshopId: number;
}

const AddWorkshop: FC<IProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const workshops: Workshop[] = useSelector(getWorkshops);
  const recentlyAddedIndex = useSelector(getRecentlyAddedIndex);

  const currentSlide = useRef<number>(0);
  const carouselStepsRef = useRef<CarouselRef>(null);
  const stepsContentCarouselRef = useRef<CarouselRef>(null);
  const [workshop, setWorkshop] = useState<Workshop>();
  const currentStepRef = useRef<number>(0);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [packageList, setPackageList] = useState<IPackage[]>([]);
  const [welcomeModal, setWelcomeModal] = useState<boolean>(false);
  const [savingData, setSavingData] = useState<boolean>(false);

  /*=============================================
  =                  functions                  =
  =============================================*/
  const finishSetup = () => {
    dispatch(clearRecentlyAdded());
    setWelcomeModal(false);
    history.push("/citas");
  };

  const UpdateWorkshop = (workshop: Workshop) => {
    dispatch(updateWorkshop(workshop)).then((result: any) => {
      if (result.meta.requestStatus === "fulfilled")
        setWorkshop(result.payload);
    });
  };

  const savePackages = async (pkgList: IPackage[]) => {
    if (workshop === undefined || workshop.id === undefined) return;
    setSavingData(true);
    const packages = pkgList.map((pack) => {
      return {
        ServicePackagesetId: pack.servicePackageSetId,
        Price: pack.price,
        Comment: pack.comment,
      };
    });
    const request = await PackagesAPI.postWorkshopPackages(
      workshop.id,
      packages
    )
      .then((response) => response.data)
      .catch(() => false);
    setSavingData(false);
    if (request) {
      setPackageList(pkgList);
      setCurrentStep(3);
    } else {
      CkMessage({ type: "error", text: "Se produjo un error al guardar los paquetes"});
    }
  };

  const saveServices = async (svList: any[]) => {
    // Prevent errors
    if (workshop === undefined || workshop.id === undefined) return;

    // save request
    const request = await ServicesAPI.updateServices(workshop.id, svList)
      .then((response) => response.data)
      .catch(() => false);
    setSavingData(false);

    // Return error if there's any
    if (request === false) {
      CkMessage({ type: "error", text: "Se produjo un error al guardar los paquetes"});

      return;
    }

    const isPackageEmpty = !packageList.some((res: any) => res.price > 0);
    if ((!packageList || isPackageEmpty) && (!svList || svList.length === 0)) {
      setSavingData(false);
      CkMessage({ type: "warning", text: "Selecciona al menos un paquete o servicio."});

      return;
    } else if (!packageList && !svList) {
      setSavingData(false);
      CkMessage({ type: "warning", text: "Selecciona al menos un paquete o servicio."});

      return;
    }

    // If everything is great
    setSavingData(false);
    setWelcomeModal(true);
  };

  const scrollTop = () => {
    document.querySelector(".add-workshop-page") !== null &&
      document.querySelector(".add-workshop-page")!.scrollIntoView({
        behavior: "smooth",
      });
  };

  const updateCarouselHeight = () => {
    if (stepsContentCarouselRef.current === null) return null;
    const container =
      stepsContentCarouselRef.current.innerSlider.list.querySelectorAll(
        ".slick-slide"
      )[currentSlide.current] ||
      stepsContentCarouselRef.current.innerSlider.list.querySelectorAll(
        ".slick-current"
      )[0];
    if (container === undefined) return null;
    const childrens = [...(container!.childNodes! || [])];
    const clientHeight = childrens.reduce((prev, current) => {
      return prev + current.clientHeight;
    }, 100);
    stepsContentCarouselRef.current!.innerSlider.list.style.minHeight = `${clientHeight}px`;
  };

  const afterSlideChange = (current: number, gopTop: boolean = true) => {
    gopTop && scrollTop();
    updateCarouselHeight();
  };

  /*============  End of functions  ============*/

  /*=============================================
  =                   effects                   =
  =============================================*/
  // Initializations
  useEffect(() => {
    dispatch(setCurrentSection("Agregar taller"));
  }, []);

  // This route is valid only for new workshops
  useEffect(() => {
    // If recentlyAddedIndex we must redirect
    if (recentlyAddedIndex === undefined) {
      CkMessage({ type: "error", text: "Error al configurar el taller"});

      history.push("/citas");
      return;
    }
    const workshop = workshops[recentlyAddedIndex];
    const isClone = !!workshop?.isClone;
    if (
      !isClone &&
      (workshop === undefined ||
        workshop?.email === undefined ||
        workshop?.email.length > 0)
    ) {
      CkMessage({ type: "error", text: "Error al configurar el taller"});

      history.push("/citas");
      return;
    }
    // const workshop = workshops[workshops.length - 1];
    setWorkshop(workshop);
  }, [recentlyAddedIndex]);

  useEffect(() => {
    if (currentStep === currentStepRef.current) return;
    currentStepRef.current = currentStep;
    scrollTop();
    // updateCarouselHeight();
    carouselStepsRef.current && carouselStepsRef.current.goTo(currentStep);
    stepsContentCarouselRef.current &&
      stepsContentCarouselRef.current.goTo(currentStep);
  }, [currentStep]);

  useEffect(() => {
    if (workshop === undefined) return;
    PackagesAPI.getPackagesDetail().then((response: any) => {
      const packages = response.data.filter(
        (pack: IPackage) => pack.servicePackage.isActive
      );

      PackagesAPI.getWorkshopPackages(workshop.id!).then((res: any) => {
        const full = packages.map((pack: IPackage) => {
          const match = res.data.find(
            (a: WorkshopPackage) =>
              a.servicePackageSetId === pack.servicePackageSetId
          );
          if (match) {
            return {
              ...pack,
              price: match.price,
              comment: match.comment ? match.comment : "",
            };
          } else {
            return { ...pack, price: 0, comment: "" };
          }
        });
        setPackageList(full);
      });
    });
  }, [workshop]);

  /*============  End of effects  =============*/

  /*=============================================
  =                  components                 =
  =============================================*/
  const ProcessSteps = () => {
    return (
      <section className="process-steps">
        <Steps items={steps} current={currentStep} labelPlacement="vertical" />
        <Carousel
          className="process-steps-carousel"
          ref={carouselStepsRef}
          dots={false}
          arrows={false}
          swipeToSlide={false}
          swipe={false}
          touchMove={false}
          infinite={false}
        >
          {steps.map((step, index) => (
            <div className="carousel-step" key={`carousel-step-slide-${index}`}>
              <p className="index">{index + 1}</p>
              <p className="title">{step.title}</p>
            </div>
          ))}
        </Carousel>
      </section>
    );
  };
  /*===========  End of components  ============*/

  const steps = [
    {
      title: "Detalles del taller",
      content: (
        <WorkshopSetup
          workshop={workshop}
          UpdateWorkshop={UpdateWorkshop}
          setIsNewWs={() => {}}
          nextSetting={() => setCurrentStep(1)}
          nextStepText={"Guardar y continuar"}
          prevSetting={undefined}
          setStopLoop={() => null}
          hidePackagesAndServices={true}
          topAffix={false}
        />
      ),
    },
    {
      title: "Encuesta",
      content: (
        <QuizModal
          visible={currentStep === 1}
          setVisible={() => {}}
          workshopId={workshop && (workshop.id || "0")}
          contentWrapper="section"
          prevStep={() => setCurrentStep(0)}
          prevStepText={"Anterior"}
          nextStep={() => setCurrentStep(2)}
          showTitle={false}
          onStepChange={afterSlideChange}
        />
      ),
    },
    {
      title: "Selección de paquetes",
      content: (
        <WorkshopPackages
          packageList={packageList}
          isOpen={true}
          setIsOpen={() => {}}
          form={form}
          saveData={() => {}}
          contentWrapper="section"
          prevStep={() => setCurrentStep(1)}
          prevStepText={"Anterior"}
          nextStep={savePackages}
          nextStepText={"Guardar e ir a siguiente"}
          savingData={savingData}
        />
      ),
    },
    {
      title: "Selección de servicios",
      content: (
        <WorkshopServices
          isOpen={true}
          setIsOpen={() => {}}
          form={form}
          saveData={() => {}}
          workshopId={(workshop && workshop.id) || "0"}
          contentWrapper="section"
          prevStep={() => setCurrentStep(2)}
          prevStepText={"Anterior"}
          nextStep={saveServices}
          nextStepText={"Guardar y finalizar"}
          savingData={savingData}
          workshop={workshop}
        />
      ),
    },
  ];

  return (
    <section className="add-workshop-page">
      {ProcessSteps()}
      <div className="step-process-container">
        {/* {steps[currentStep].content && steps[currentStep].content} */}
        <Carousel
          ref={stepsContentCarouselRef}
          className="steps-content-carousel"
          infinite={false}
          swipeToSlide={false}
          swipe={false}
          touchMove={false}
          draggable={false}
          accessibility={false}
          dots={false}
          arrows={false}
          slidesPerRow={1}
          slidesToShow={1}
          slidesToScroll={1}
          /* adaptiveHeight={true} */
          speed={1000}
          afterChange={afterSlideChange}
          beforeChange={(_, to) => (currentSlide.current = to)}
          centerMode
          centerPadding="0"
        >
          {steps && steps.map((step) => step.content)}
        </Carousel>
      </div>
      <Modal
        className="add-workshop-welcome-modal"
        open={welcomeModal}
        footer={null}
        closeIcon={<CKCloseIcon />}
        onCancel={finishSetup}
      >
        <h1>Hemos terminado</h1>
        <p>
          La información ingresada ha sido registrada correctamente. Se están
          revisando tus respuestas para aprobar la visibilidad del taller.
        </p>
        <Button className="--custom" type="default" onClick={finishSetup}>
          Ir al inicio
        </Button>
      </Modal>
    </section>
  );
};

export default AddWorkshop;
