import React, { useEffect, useState } from "react";
import { setCurrentSection } from "../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppointmentAPI } from "../../app/Services/axios";
import { AppointmentDetails } from "../../app/Components/Appointment/AppointmentDetails";
import { useHistory } from "react-router-dom";
import "./styles.css";
import { Product } from "../../app/models/oli";
import {
  getAppointment,
  getAppointmentError,
  getAppointmentLoading,
} from "../../stores";
import { WheelLoader } from "../../app/Components/Loaders";
import { CkMessage } from "../../CkUI";

interface IProps {
  hours: any;
  packages: any;
  services: any;
  workshopId: any;
}
export const AppointmentDetailsPage: React.FC<IProps> = ({
  hours,
  packages,
  services,
  workshopId,
}) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const { appointmentId } = useParams();

  const loadingAppointment: boolean = useSelector(getAppointmentLoading);
  const [error, errorMessage]: [boolean, string?] =
    useSelector(getAppointmentError);
  const appointmentEdited = useSelector(getAppointment);

  const [appointmentData, setAppointmentData] = useState<any>(null);
  const [loadingAppointmentData, setLoadingAppointmentData] =
    useState<boolean>(true);

  const [product, setProduct] = useState<Array<Product>>([]);
  const [productSize, setProductSize] = useState<number>(0);
  const [showProductRecModal, setShowProductRecModal] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(setCurrentSection("Administrar agenda"));
  }, []);

  useEffect(() => {
    if (
      appointmentEdited &&
      appointmentEdited?.id &&
      appointmentEdited.id === appointmentId
    ) {
      setAppointmentData({
        extendedProps: appointmentEdited,
        workshopId: workshopId,
        publicId: appointmentId || "",
      });
    }
  }, [appointmentEdited]);

  useEffect(() => {
    if (
      appointmentData &&
      appointmentData.extendedProps.statusCode === "CANCE"
    ) {
      dispatch(setCurrentSection("Cita Cancelada"));
    } else {
      dispatch(setCurrentSection("Administrar agenda"));
    }
  }, [appointmentData]);

  useEffect(() => {
    setLoadingAppointmentData(true);
    AppointmentAPI.getAppById(appointmentId)
      .then((response) =>
        setAppointmentData({
          extendedProps: response.data,
          workshopId: workshopId,
          publicId: appointmentId || "",
        })
      )
      .catch((err) => {
        if (err && err.message.includes("400")) {
          CkMessage({type: "error", text: "No tienes acceso a la información solicitada"});

          history.push("/citas");
        }
        setAppointmentData(null);
      })
      .finally(() => setLoadingAppointmentData(false));
  }, [appointmentId]);

  const reloadAppointmentData = () => {
    setLoadingAppointmentData(true);
    AppointmentAPI.getAppById(appointmentId)
      .then((response) =>
        setAppointmentData({
          extendedProps: response.data,
          workshopId: workshopId,
          publicId: appointmentId || "",
        })
      )
      .catch((err) => setAppointmentData(null))
      .finally(() => setLoadingAppointmentData(false));
  };

  useEffect(() => {
    if (!loadingAppointment && !error && errorMessage === "updated") {
    }
  }, [loadingAppointment, error, errorMessage]);

  return (
    <section 
      className="page-container appointment-details-page"
    >
      <WheelLoader  spinning={loadingAppointmentData} />
      {!loadingAppointmentData && (
        <AppointmentDetails
          dataInfo={appointmentData}
          workshopId={workshopId}
          workshopServiceList={services}
          packagesList={packages}
          hours={hours}
          reloadAppointmentData={reloadAppointmentData}
          product={product}
          setProduct={setProduct}
          setProductSize={setProductSize}
          productSize={productSize}
          showProductRecModal={showProductRecModal}
          setShowProductRecModal={setShowProductRecModal}
        />
      )}
    </section>
  );
};
