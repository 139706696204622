import React, { useEffect, memo, useRef } from "react";

import { Image, Upload, message, Button } from "antd";
import defaultAvatar from "../../../assets/Images/avatar placeholder.svg";

import { BlobServiceClient } from "@azure/storage-blob";

import NewCoworker from "../NewCoworker/index";

import { FormInstance } from "antd/lib/form";
import { User } from "shared/src/data-models/User";

import { gtmAddLayer } from "../../../app/Utilities";
import Compressor from "compressorjs";
import moment from "moment";
import { CkButton, CkMessage } from "../../../CkUI";
import "./style.css";


interface IProps {
  // Interaction Card
  index: number;
  editable: boolean;
  setEditable: Function;
  setDeleteUserModal: Function;
  coworkerDeployed: undefined | number;
  setCoworkerDeployed: Function;
  updateCoworker: Function;
  addNewCoworker: Function;
  newPhoto: string;
  setNewPhoto: Function;
  // Info
  title: string;
  subTitle: string | undefined;
  icon: string | undefined;
  // TO FORM
  form: FormInstance;
  sasToken: string;
  // Functions
  HandlePhotoChange: Function;
  // Initial data
  user: User | any;
}

const account = process.env.REACT_APP_STORAGEACCOUNT as string;
const containerName = "up-images-container";
let blobService: any = null;
let containerClient: any = null;

const CoworkerCard: React.FC<IProps> = ({
  // Interaction Card
  index,
  editable,
  setEditable,
  setDeleteUserModal,
  coworkerDeployed,
  setCoworkerDeployed,
  updateCoworker,
  addNewCoworker,
  newPhoto,
  setNewPhoto,
  // Info
  title,
  subTitle,
  icon,
  // TO FORM
  form,
  sasToken,
  // Functions
  HandlePhotoChange,
  // Initial data
  user,
}) => {
  const loadingMessageKey = useRef<string>("");

  useEffect(() => {
    if (sasToken) {
      blobService = new BlobServiceClient(`${account}?${sasToken}`);
      containerClient = blobService.getContainerClient(containerName);
    }
  }, [sasToken]);

  const resetForm = () => {
    form.resetFields();
  };

  const setFormValues = () => {
    form.setFieldsValue({
      name: user.name,
      lastName: user.lastName,
      rol: user.userRoles[0].roleCode,
      dateOfBirth:
        user.dateOfBirth && user.dateOfBirth.length > 0
          ? moment(user.dateOfBirth)
          : undefined,
      phone: user.phone,
      email: user.email,
      roleId: true,
      isVisible: user.isVisible,
    });
  };

  const props = {
    //fileList,
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload: (file: any) => {
      let imgType: Object = {
        "image/png": true,
        "image/jpg": true,
        "image/jpeg": true,
      };
      if (!imgType.hasOwnProperty(file.type)) {
        CkMessage({
          type: "error",
          text: `${file.name} no es un formato soportado.`,
        });
      }
      return imgType.hasOwnProperty(file.type);
    },
    customRequest: ({ onSuccess, onError, file }: any) => {
      uploadImage(onSuccess, onError, file);
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === "uploading") {
        loadingMessageKey.current = CkMessage({
          type: "loading",
          text: `Subiendo imagen ${info.file.name}`,
          ...(loadingMessageKey.current ? {messageToDestroy: loadingMessageKey.current} : {})
        });
      } else if (status === "done") {
        CkMessage({
          type: "success",
          text: `${info.file.name} subida exitosamente.`,
          ...(loadingMessageKey.current ? {messageToDestroy: loadingMessageKey.current} : {})
        });
        if (loadingMessageKey.current) loadingMessageKey.current = "";
        if (true) {
          gtmAddLayer({
            event: "click_upload_image_on_setup",
          });
        }
      } else if (status === "error") {
        CkMessage({
          type: "error",
          text: `${info.file.name} error al subir imagen`,
          ...(loadingMessageKey.current ? {messageToDestroy: loadingMessageKey.current} : {})
        });
        if (loadingMessageKey.current) loadingMessageKey.current = "";
      }
    },
  };

  const uploadImage = async (
    onSuccess: Function,
    onError: Function,
    file: any
  ) => {
    let date = new Date();
    let fileName = `${date.getTime()}_${file.name}`;
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);

    try {
      new Compressor(file, {
        quality: 0.6,
        convertTypes:
          "image/png, image/webp, image/gif, image/tiff, image/apng, image/ico, image/cur, image/ai, image/svg, image/raw, image/jfif",
        convertSize: 2000000,
        async success(result) {
          await blockBlobClient.upload(result, result.size);
          HandlePhotoChange(blockBlobClient.url.split("?")[0]);
          setNewPhoto(blockBlobClient.url.split("?")[0]);
          onSuccess();
        },
      });
    } catch (error) {
      onError();
    }
  };

  const ImageRender = () => {
    return index == -1 ? (
      //@ts-ignore
      <Image
        preview={false}
        className={`cw-logo ${newPhoto.length == 0 ? "fallback-logo" : ""}`}
        src={newPhoto.length > 0 ? `${newPhoto}?${sasToken}` : defaultAvatar}
        fallback={defaultAvatar}
      />
    ) : (
      //@ts-ignore
      <Image
        preview={false}
        className={`cw-logo ${icon == undefined ? "fallback-logo" : ""}`}
        src={icon ? icon : defaultAvatar}
        fallback={defaultAvatar}
      />
    );
  };

  return (
    <div className="coworker-card-container" id="align-end-parent">
      <div className="cw-card-divisor">
        <div className="cw-info">
          {(index == -1 ? true : editable) && coworkerDeployed == index ? (
            //@ts-ignore
            <Upload {...props} showUploadList={false}>
              <figure>
                {ImageRender()}
                <figcaption>
                  {/* @ts-ignore */}
                  <Button style={{ padding: 0 }} className="btn-photo">
                    {"Cambiar "}
                    <span>foto</span>
                  </Button>
                </figcaption>
              </figure>
            </Upload>
          ) : (
            ImageRender()
          )}

          <div className="cw-info-container">
            <div
              className="cw-name"
            >
              <div>
                <div className="name-wrapper">
                  <p className="name">{title}</p>
                  {coworkerDeployed == index && index !== -1 &&
                    <CkButton
                      className="toogle-card-btn"
                      icon="pencil"
                      shape="circle"
                      variant="text"
                      onClick={() => setEditable(true)}
                    />}
                </div>
                <p className="desc">{subTitle}</p>
              </div>
              {/* @ts-ignore */}

              <CkButton
                className="toogle-card-btn"
                icon={coworkerDeployed == index ? "caret-up" : "caret-down"}
                shape="circle"
                variant="text"
                onClick={() => {
                  if (coworkerDeployed == index) {
                    setCoworkerDeployed(undefined);
                    resetForm();
                  } else {
                    setCoworkerDeployed(index);
                    setFormValues();
                  }
                  setEditable(false);
                }}
              />
            </div>

            {/* {coworkerDeployed == index && (
              <div className="cw-edit">
                <span
                  className="edit-link"
                  onClick={() => {
                    setEditable(!editable);
                    setFormValues();
                  }}
                >
                  <p>{editable ? "Cancelar" : "Editar"}</p>
                  <EditOutlined className="edit-icon" />
                </span>
              </div>
            )} */}

            {coworkerDeployed == index && (
              <NewCoworker
                // Interaction Card
                index={index}
                isNew={index == -1 ? true : false}
                editable={index == -1 ? true : editable}
                setDeleteUserModal={setDeleteUserModal}
                setEditable={setEditable}
                setFormValues={setFormValues}
                // TO FORM
                form={form}
                // Functions
                updateCoworker={updateCoworker}
                addNewCoworker={addNewCoworker}
                // Initial data
                user={user}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CoworkerCard);
