import React, { memo } from "react";

import {
  Input,
  Form,
  DatePicker,
  message,
  Select,
  Button,
  Checkbox,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { User } from "shared/src/data-models/User";

import { FormInstance } from "antd/lib/form";
import { CkButton, CkCheckbox, CkMessage } from "../../../CkUI";
import "./style.css";


const Option = Select;

interface IProps {
  // Interaction Card
  index: number;
  isNew: boolean;
  editable: boolean;
  setDeleteUserModal: Function;
  setEditable: Function;
  setFormValues: Function;
  // TO FORm
  form: FormInstance;
  // Functions
  updateCoworker: Function;
  addNewCoworker: Function;
  // Initial data
  user: User | any;
}

const NewCoworker: React.FC<IProps> = ({
  // Interaction Card
  index,
  isNew,
  editable,
  setDeleteUserModal,
  setEditable,
  setFormValues,
  // TO FORM
  form,
  // Functions
  updateCoworker,
  addNewCoworker,
  // Initial data
  user,
}) => {
  //console.log("user: ", user);

  const isOwner = () => {
    return user.userRoles[0].roleCode == "OWN" ? true : false;
  };

  return (
    <Form
      name="coworker"
      form={form}
      initialValues={{ ...user, isVisible: user.isVisible }}
      onFinish={(data) => {
        if (isNew) {
          addNewCoworker();
        } else {
          updateCoworker(index);
        }
      }}
      onFinishFailed={(dataFailed) =>
        CkMessage({
          type: "error",
          text: "Asegúrate de completar correctamente los campos requeridos."
        })
      }
    >
      <div className="new-coworker-container">
        <Form.Item
          className="force-size"
          name="name"
          label="Nombre"
          rules={[
            {
              required: true,
              type: "string",
              message: "Ingresa el nombre del empleado.",
            },
          ]}
        >
          <Input
            placeholder="Escribe el nombre del empleado"
            disabled={!editable}
          />
        </Form.Item>
        <Form.Item
          className="force-size"
          name="lastName"
          label="Apellido"
          rules={[
            {
              required: true,
              type: "string",
              message: "Ingresa el apellido del empleado.",
            },
          ]}
        >
          <Input
            placeholder="Escribe los apellidos del empleado"
            disabled={!editable}
          />
        </Form.Item>
        <Form.Item
          name="rol"
          label="Rol"
          rules={[
            {
              required: true,
              type: "string",
              message: "Selecciona el rol del empleado.",
            },
          ]}
        >
          <Select placeholder="Selecciona el rol" disabled={!editable}>
            {isOwner() && (
              <Option disabled={!isOwner()} value="OWN">
                Dueño
              </Option>
            )}
            {!isOwner() && (
              <>
                <Option disabled={isOwner()} value="ADM">
                  Administrador
                </Option>
                <Option disabled={isOwner()} value="MEC">
                  Mecanico
                </Option>
              </>
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name="dateOfBirth"
          label="Fecha de nacimiento"
          rules={[
            {
              required: true,
              type: "date",
              message: "Ingresa la fecha de nacimiento del empleado.",
            },
          ]}
        >
          <DatePicker
            placeholder="Selecciona la fecha de nacimiento"
            format={"DD/MM/YYYY"}
            disabled={!editable}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Teléfono celular"
          rules={[
            {
              required: true,
              message: "Ingresa el teléfono celular del empleado.",
            },
            {
              pattern: new RegExp(/^[0-9]*$/gi),
              message: "Sólo se permiten números.",
            },
            {
              max: 10,
              min: 10,
              message: "El teléfono celular debe ser de 10 números.",
            },
          ]}
        >
          <Input
            maxLength={10}
            placeholder="Escribe el celular del empleado"
            disabled={!editable}
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="Correo electrónico"
          rules={[
            {
              required: true,
              type: "email",
              message: "Ingresa un correo electrónico válido del empleado.",
            },
          ]}
        >
          <Input
            placeholder="Escribe el correo electrónico del empleado"
            disabled={isNew ? !editable : true}
          />
        </Form.Item>
        <Form.Item name="isVisible" valuePropName="checked">
          <CkCheckbox checkboxProps={{disabled: !editable, defaultChecked: user.isVisible}}>
            Mostrar en "Nuestro equipo"{" "}
            <Tooltip title='Selecciona para mostrar este perfil en "Nuestro equipo"'>
              <InfoCircleOutlined style={{ marginLeft: "5px" }} />
            </Tooltip>
          </CkCheckbox>
        </Form.Item>
      </div>
      <div className="new-coworker-options">
        {!isNew && !isOwner() && (
          <CkButton
            color="secondary"
            variant="link"
            onClick={() => setDeleteUserModal(true)}
            block={false}
          >
            Eliminar perfil
          </CkButton>
        )}
        <div className="primary-buttons-container">
          {!isNew && editable && (
            <CkButton
              variant="secondary"
              onClick={() => {
                setEditable(!editable);
                setFormValues();
              }}
            >
              Cancelar
            </CkButton>
          )}
          {editable && <CkButton
            /* disabled={!editable} */
            htmlType="submit"
          >
            Guardar datos
          </CkButton>}
        </div>
      </div>
    </Form>
  );
};

export default memo(NewCoworker);
