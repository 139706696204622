import React, { useState, useEffect, useLayoutEffect, FC, memo } from "react";

import { Form, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { MechanicDeleteIcon } from "../../assets/SvgIcons";
import { checkIfPersonIsAdult } from "../../app/Utilities/Dates";
import { useSelector, useDispatch } from "react-redux";

import {
  addCoworker,
  updateUser,
  deleteUser,
  fetchCoworkers,
  serviceSAS,
  RoleCode,
  clearStatus,
  workshopSelected,
} from "shared";

import {
  getRoles,
  getSasToken,
  getUserAction,
  getUserError,
  getUsers,
  getUsersLoading,
  setCurrentSection,
} from "../../stores";

import { Workshop } from "shared/src/data-models/Workshop";

import { ConfirmationModal } from "../../app/Components/Modals/ConfirmationModal";
import CoworkerCard from "./CoworkerCard/index";

import { gtmAddLayer } from "../../app/Utilities";
import { CkAddButton, CkNavigationButton, CkMessage } from "../../CkUI";
import "./style.css";


interface IProps {
  nextSetting: Function;
  prevSetting: Function;
  workshops: Array<Workshop>;
  newWsModal: Function;
  wsIndex: number;
  workshopId: string | undefined;
}

interface IUserRoles {
  isConfigured: boolean;
  roleCode: string;
  workshopId: string;
}

export interface infoCoworker {
  visible: boolean;
  isUpdate: boolean;
  index: number;
  name: string;
  lastName: string;
  roleId: string;
  phone: string;
  email: string;
  workshopId: string;
  dateOfBirth: string | undefined;
  photoUrl: string;
}

let intervalID: any = null;

const AddCoworkersNEW: FC<IProps> = ({
  nextSetting,
  prevSetting,
  workshopId,
  newWsModal,
  wsIndex,
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch<any>();

  const roles = useSelector(getRoles);
  const coworkers = useSelector(getUsers);
  const sasToken: string = useSelector(getSasToken);
  const loading: boolean = useSelector(getUsersLoading);
  const [error, errorMessage] = useSelector(getUserError);
  const [action, actionSuccess] = useSelector(getUserAction);

  const [editable, setEditable] = useState<boolean>(false);
  const [newEmployee, setNewEmployee] = useState<boolean>(false);
  const [coworkerDeployed, setCoworkerDeployed] = useState<undefined | number>(
    undefined
  );

  const [newPhoto, setNewPhoto] = useState<string>("");

  const [deleteUserModal, setDeleteUserModal] = useState<boolean>(false);

  useLayoutEffect(() => {
    dispatch(workshopSelected(wsIndex));
  }, [dispatch, wsIndex]);

  useEffect(() => {
    intervalID = setInterval(getSasTokenAzure, 240000);
    dispatch(fetchCoworkers(workshopId ? workshopId : ""));
    dispatch(setCurrentSection("Administrar personal"));

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  useEffect(() => {
    if (error && errorMessage?.split("|")[0] === "Coworker") {
      CkMessage({ type: "error", text: errorMessage.split("|")[1]});

      dispatch(clearStatus());
    }
  }, [dispatch, error, errorMessage]);

  useEffect(() => {
    if (action === "added" && actionSuccess) {
      // GOOGLE TAG
      gtmAddLayer({
        total_workers: coworkers.length,
        workshop_ID: workshopId,
        event: "add_edit_user",
      });

      form.setFieldsValue({
        visible: false,
        isUpdate: false,
        index: -1,
        name: "",
        lastName: "",
        roleId: "MEC",
        phone: "",
        email: "",
        workshopId: "",
      });
    }
  }, [action, actionSuccess, coworkers.length, form, workshopId]);

  const getSasTokenAzure = async () => {
    dispatch(serviceSAS());
  };

  const clearAll = () => {
    form.resetFields();
    setEditable(false);
    setNewEmployee(false);
    setCoworkerDeployed(undefined);
  };

  const addNewCoworker = async () => {
    await form.validateFields().then((fields) => {
      try {
        if (!checkIfPersonIsAdult(fields.dateOfBirth)) {
          form.setFields([
            {
              name: "dateOfBirth",
              errors: ["El empleado debe ser mayor de edad"],
            },
          ]);
          return;
        }

        dispatch(
          addCoworker({
            id: "0",
            name: fields.name,
            lastName: fields.lastName,
            phone: fields.phone.split("-").join(""),
            email: fields.email,
            userRoles: [
              {
                workshopId: workshopId ? workshopId : "",
                roleCode: fields.rol as RoleCode,
              },
            ],
            azureCode: "",
            dateOfBirth: fields.dateOfBirth,
            photoUrl: newPhoto,
            isVisible: fields.isVisible,
          })
        ).then((result: any) => {
          // GOOGLE TAG
          gtmAddLayer({
            total_workers: coworkers.length + 1,
            workshop_ID: workshopId,
            event: "add_edit_user",
          });
          CkMessage({ type: "success", text: "¡Empleado agregado exitosamente!"});
          CkMessage({ type: "success", text: "¡Invitación enviada!"});
          setNewPhoto("");
          clearAll();
        });
      } catch (e) {
        CkMessage({ type: "error", text: "¡Ocurrió un problema al agregar el empleado!"});
      }
    });
  };

  const returnUpdatedRoles = (
    workshopId: string,
    userRoles: IUserRoles[] | any[],
    newRole: string
  ) => {
    let arrayFiltered = userRoles.filter(
      (res: IUserRoles) => res.workshopId != workshopId
    );

    arrayFiltered.push({
      roleCode: newRole,
      workshopId: workshopId,
    });

    return arrayFiltered;
  };

  const removeCoworker = async (index: number) => {
    setDeleteUserModal(false);

    await form.validateFields().then((fields) => {
      try {
        dispatch(deleteUser(coworkers[index].id)).then((result: any) => {
          // GOOGLE TAG
          gtmAddLayer({
            total_workers: coworkers.length + 1,
            workshop_ID: workshopId,
            event: "add_edit_user",
          });
          CkMessage({ type: "success", text: "¡Empleado eliminado exitosamente!"});

          setEditable(false);
          setCoworkerDeployed(undefined);
        });
      } catch (e) {
        CkMessage({ type: "error", text: "¡Ocurrió un problema al eliminar el empleado!"});
      }
    });
  };

  const updateCoworker = async (index: number, photoUrl?: string) => {
    await form.validateFields().then((fields) => {
      try {
        dispatch(
          updateUser({
            id: coworkers[index].id,
            name: photoUrl ? coworkers[index].name : fields.name,
            lastName: photoUrl ? coworkers[index].lastName : fields.lastName,
            //@ts-ignore
            isVisible: photoUrl ? coworkers[index].isVisible : fields.isVisible,
            userRoles: photoUrl
              ? coworkers[index].userRoles
              : returnUpdatedRoles(
                  workshopId!,
                  coworkers[index].userRoles,
                  fields.rol
                ),
            phone: photoUrl
              ? coworkers[index].phone
              : fields.phone.split("-").join(""),
            email: photoUrl ? coworkers[index].email : fields.email,
            azureCode: coworkers[index].azureCode,
            dateOfBirth: photoUrl
              ? coworkers[index].dateOfBirth
              : fields.dateOfBirth,
            photoUrl: photoUrl ? photoUrl : coworkers[index].photoUrl,
          })
        ).then((result: any) => {
          // GOOGLE TAG
          gtmAddLayer({
            total_workers: coworkers.length + 1,
            workshop_ID: workshopId,
            event: "add_edit_user",
          });
          CkMessage({ type: "success", text: "¡Empleado actualizado exitosamente!"});

          setNewPhoto("");
        });
      } catch (e) {
        CkMessage({
          type: "error",
          text: "¡Ocurrió un problema al actualizar el empleado!"
        });
      }
    });
  };

  const handlePhotoChange = (photoUrl: string) => {
    if (coworkerDeployed !== undefined) {
      updateCoworker(coworkerDeployed, photoUrl);
    } else if (coworkerDeployed == -1) {
      setNewPhoto(photoUrl);
    }
  };

  const onFinish = () => {
    nextSetting();
  };

  const prev = () => {
    prevSetting();
  };

  const getRoleCode = (userRoles: any[]) => {
    if (userRoles && userRoles.length > 0) {
      let rolesFltered: any[] = userRoles.filter(
        (res) => res.workshopId == workshopId
      );

      if (rolesFltered && rolesFltered.length > 0) {
        return rolesFltered[0].roleCode;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  const isOwner = (user: any) => {
    return user.userRoles[0].roleCode == "OWN" ? true : false;
  };

  const setDefaultFormValues = () => {
    form.setFieldsValue({
      name: "",
      lastName: "",
      rol: "MEC",
      dateOfBirth: "",
      phone: "",
      email: "",
      roleId: true,
    });
  };

  return (
    <div className="page-container my-coworkers-container">
      <div className="header-options">
        <CkNavigationButton
          onClick={() => {
            prev();
            dispatch(setCurrentSection("Mis talleres"));
          }}
          icon={"arrow-left-outlined"}
        >
          Regresar
        </CkNavigationButton>
      </div>
      <div className="list-container">
        {coworkers.map(
          (coworker, index) =>
            !isOwner(coworker) && (
              <CoworkerCard
                // Interaction Card
                index={index}
                editable={editable}
                setEditable={setEditable}
                setDeleteUserModal={setDeleteUserModal}
                coworkerDeployed={coworkerDeployed}
                setCoworkerDeployed={setCoworkerDeployed}
                updateCoworker={updateCoworker}
                addNewCoworker={addNewCoworker}
                newPhoto={newPhoto}
                setNewPhoto={setNewPhoto}
                // Info
                title={`${coworker.name} ${coworker.lastName}`}
                subTitle={
                  roles.find(
                    (role) => role.roleCode === coworker.userRoles[0].roleCode
                  )?.roleName
                }
                icon={
                  coworker.photoUrl !== "" && sasToken
                    ? `${coworker.photoUrl}?${sasToken}`
                    : undefined
                }
                // TO FORM
                form={form}
                sasToken={sasToken}
                // Functions
                HandlePhotoChange={handlePhotoChange}
                // Initial data
                user={{
                  id: coworker.id,
                  name: coworker.name,
                  lastName: coworker.lastName,
                  phone: coworker.phone,
                  email: coworker.email,
                  userRoles: [
                    {
                      workshopId: workshopId ? workshopId : "",
                      roleCode: getRoleCode(coworker.userRoles) as RoleCode,
                    },
                  ],
                  azureCode: "",
                  dateOfBirth: coworker.dateOfBirth,
                  photoUrl: coworker.photoUrl,
                  isVisible: coworker.isVisible,
                }}
              />
            )
        )}
        {newEmployee && (
          <CoworkerCard
            // Interaction Card
            index={-1}
            editable={editable}
            setEditable={setEditable}
            setDeleteUserModal={setDeleteUserModal}
            coworkerDeployed={coworkerDeployed}
            setCoworkerDeployed={setCoworkerDeployed}
            updateCoworker={updateCoworker}
            addNewCoworker={addNewCoworker}
            newPhoto={newPhoto}
            setNewPhoto={setNewPhoto}
            // Info
            title={"Nuevo empleado"}
            subTitle={""}
            icon={undefined}
            // TO FORM
            form={form}
            sasToken={sasToken}
            // Functions
            HandlePhotoChange={handlePhotoChange}
            // Initial data
            user={{
              id: "0",
              name: "",
              lastName: "",
              phone: "",
              email: "",
              userRoles: [
                {
                  workshopId: workshopId ? workshopId : "",
                  roleCode: "MEC" as RoleCode,
                },
              ],
              azureCode: "",
              dateOfBirth: "",
              photoUrl: undefined,
              isVisible: false,
            }}
          />
        )}
      </div>
      <div className="add-coworker-container">
        <CkAddButton
          className="add-coworker-button"
          onClick={() => {
            setCoworkerDeployed(-1);
            setNewEmployee(true);
            setDefaultFormValues();
          }}
        >
          Agregar empleado
        </CkAddButton>
      </div>
      <ConfirmationModal
        visible={deleteUserModal}
        title="Borrar datos del mecánico"
        message="¿Estás de seguro que quieres eliminar este perfil?"
        icon={<MechanicDeleteIcon />}
        onOk={() =>
          coworkerDeployed ? removeCoworker(coworkerDeployed) : null
        }
        onCancel={() => {
          setDeleteUserModal(false);
        }}
      />
    </div>
  );
};

export default memo(AddCoworkersNEW);
